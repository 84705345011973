import React, { useState, useEffect } from 'react'
import { Edit, Trash2, Eye, EyeOff } from 'react-feather'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import PropTypes from 'prop-types'
import { ToastContainer, toast } from 'react-toastify'
import axios from 'axios'
import { calMaxPage } from '../../../common/Functions/CommonFunctions'
import {  createLogoApi, getCountriesApi,updateCustApi } from '../../../common/Api'
import { Modal, Pagination, Spinner } from 'react-bootstrap'

const schema = yup
  .object()
  .shape({
    name: yup.string().max(30, '30 characters allowed !').required('Name is required !'),
    email: yup.string().required('Email is required !').email('Please enter valid email !'),
    username: yup.string().required('Username is required !'),
    //adminEmail: yup.string().required('Email is required !').email('Please enter valid email !'),
    website: yup.string().required('Website is required !'),
    contactnumber: yup.string().matches(/^\d+$/, 'Required').max(15, '15 characters allowed !'),
    // .matches(/^[6-9][0-9]{9}$/, "Enter valid contact number !"),
    address: yup.string().nullable().required('Address is required !'),
    // state: yup.array().nullable().required('State is required !'),
    // city: yup.array().nullable().required('City is required !'),
    //countryCode: yup.array().nullable().required('Country Code is required !'),
    country: yup.string().nullable().required('Country is required !'),
    // isActive: yup.string().nullable().required('Status is required !'),
    customertype: yup
      .string()
      .nullable()
      .required('Customer type is required !')
      .matches(/^((?!Select).)*$/, 'Please select valid option'),
    logo: yup.mixed().test('fileSize', 'The file is too large', (value) => {
      if (!value?.length) return true // attachment is optional
      return value[0].size <= 5000000
    }),
  })
  .required()

function EditCustomer(props) {
  const [visible, setVisible] = useState(false)
  const [countries, setCountries] = useState([])
  const [showPassword, setShowPassword] = useState(false)
  const [sameAsEmail, setSameAsEmail] = useState(false)

  const { register, handleSubmit, formState, control, watch, setValue, getValues, reset } = useForm(
    {
      resolver: yupResolver(schema),
      mode: 'onChange',
    },
  )
  let { errors } = formState

  useEffect(() => {
    //console.log('sameAsEmail', sameAsEmail)
    if (sameAsEmail) {
      const email = getValues('email')
      setValue('username', email)
    }
  }, [sameAsEmail])

  useEffect(() => {
    //console.log('props.data', props.data)
    if (visible == true) {
      getCountriesApi().then(
        (res) => {
          console.log('res', res)
          let countries = res.data.data.response

          setCountries(countries)
        },
        (err) => {
          console.log('err', err)
        },
      )
    }
  }, [visible, props.data])

  const onSubmit = (data) => {
    console.log('data', data)
    console.log('props.data', props.data)

    data.signature = ''
    data.logo = data.logo[0]
    data.isactive = true
    // data.applicationId = 'resthours'

    if (data !== '') {
      if (data.logo !== undefined) {
        createLogoApi({
          file_name: data.logo.name,
          folderName: 'logo',
        }).then(
          async (res) => {
            console.log('res', res)
            await axios.put(res.data.data.response.url, data.logo).then(
              (sres) => {
                console.log('sres', sres)
                data.logo = res.data.data.response.filename
                fireApi(data)
              },
              (serr) => {
                console.log('serr', serr)
                toast.error('Failed uploading saving logo!', {
                  position: 'top-right',
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
                })
                delete data.logo
                fireApi(data)
              },
            )
          },
          (err) => {
            console.log('err', err)
          },
        )
      } else {
        delete data.logo
        fireApi(data)
      }
    } else {
      errors.showMessages()
    }
  }

  function fireApi(data) {
    updateCustApi(data, { id: props.data.id }).then(
      (res) => {
        console.log('res', res)
        setVisible(!visible)
        props.fetchUsers(props.page)
        toast.success('Customer updated successfully', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
        reset()
      },
      (err) => {
        console.log('err', err)
        if(err.response.data.data.statusCode === 400){
          toast.error('Server Error!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
        }
      },
    )
  }

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    
        <Edit size={18} className="cursor-pointer " onClick={() => setVisible(!visible)} />
     

      <Modal size="lg" show={visible} onHide={() => setVisible(false)} backdrop="static">
        <form className="">
          <Modal.Header className="bg-primary-blue" closeButton>
            <Modal.Title className="">Update Customer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Name'}</label>
                  <input
                    className={errors.name ? 'form-control is-invalid' : 'form-control'}
                    type="text"
                    placeholder="Name*"
                    name="name"
                    {...register('name')}
                    defaultValue={props.data?.name}
                  />

                  <span className="text-danger">{errors.name?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Email'}</label>
                  <input
                    className={errors.email ? 'form-control is-invalid' : 'form-control'}
                    type="email"
                    name="email"
                    placeholder="Email Address*"
                    {...register('email')}
                    defaultValue={props.data?.email}
                  />

                  <span className="text-danger">{errors.email?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Username'}</label>
                  <input
                    className={errors.username ? 'form-control is-invalid' : 'form-control'}
                    type="text"
                    name="username"
                    placeholder="Username*"
                    defaultValue={props.data?.username == undefined ? '' : props.data?.username}
                    {...register('username')}
                  />
                  <div className="form-group form-check">
                    <input
                      type="checkbox"
                      checked={sameAsEmail}
                      onChange={(e) => setSameAsEmail(e.target.checked)}
                      className="form-check-input"
                      id="exampleCheck1"
                    />
                    <label
                      style={{ fontSize: '13px', color: 'gray' }}
                      className="form-check-label"
                      htmlFor="exampleCheck1"
                    >
                      Same As Email
                    </label>
                  </div>
                  <span className="text-danger">{errors.username?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Website'}</label>
                  <input
                    className={errors.website ? 'form-control is-invalid' : 'form-control'}
                    type="text"
                    placeholder="Website*"
                    name="website"
                    {...register('website')}
                    defaultValue={props.data?.website}
                  />

                  <span className="text-danger">{errors.website?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Customer Type'}</label>
                  <Controller
                    name="customertype"
                    control={control}
                    defaultValue={props.data?.customertype}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        onChange={onChange}
                        onBlur={onBlur}
                        // selected={value}
                        defaultValue={props.data?.customertype}
                        className={
                          errors.customertype
                            ? 'custom-select form-control is-invalid'
                            : 'custom-select form-control'
                        }
                      >
                        <option>{'--Select*--'}</option>
                        <option value={'Owner'}>Owner</option>
                        <option value={'Owner 1'}>Owner 1</option>
                      </select>
                    )}
                  />

                  <span className="text-danger">{errors.customertype?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Address'}</label>

                  <textarea
                    className={errors.address ? 'form-control is-invalid' : 'form-control'}
                    placeholder="Address*"
                    name="address"
                    {...register('address')}
                    rows="1"
                    defaultValue={props.data?.address}
                  ></textarea>

                  <span className="text-danger">{errors.address?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Country'}</label>
                  <Controller
                    name="country"
                    control={control}
                    defaultValue={props.data?.country}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        className={
                          errors.customerType
                            ? 'custom-select form-control is-invalid'
                            : 'custom-select form-control'
                        }
                      >
                        <option>{'--Select*--'}</option>
                        {countries.map((val, i) => (
                          <option
                            key={i}
                            value={val.name}
                            selected={val.name == props.data?.country}
                          >
                            {val.name}
                          </option>
                        ))}
                      </select>
                    )}
                  />

                  <span className="text-danger">{errors.country?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Contact Number'}</label>

                  <div className="form-group mb-2">
                    <input
                      defaultValue={props.data?.contactnumber}
                      className={errors.contactnumber ? 'form-control is-invalid' : 'form-control'}
                      type="number"
                      placeholder="Contact*"
                      name="contactnumber"
                      {...register('contactnumber')}
                    />
                  </div>

                  <span className="text-danger">{errors.contactnumber?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'State'}</label>

                  <input
                    defaultValue={props.data?.state}
                    className={errors.state ? 'form-control is-invalid' : 'form-control'}
                    type="text"
                    placeholder="State*"
                    name="state"
                    {...register('state')}
                  />
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'City'}</label>
                  <input
                    defaultValue={props.data?.city}
                    className={errors.city ? 'form-control is-invalid' : 'form-control'}
                    type="text"
                    placeholder="City*"
                    name="city"
                    {...register('city')}
                  />
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">Logo</label>

                  <input
                    className={errors.logo ? 'form-control is-invalid' : 'form-control'}
                    type={'file'}
                    accept="image/*"
                    {...register('logo')}
                  />

                  <span className="text-danger">{errors.logo?.message}</span>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary-blue" type="submit" onClick={handleSubmit(onSubmit)}>
              {'Save Changes'}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </React.Fragment>
  )
}

export default EditCustomer
EditCustomer.propTypes = {
  data: PropTypes.object,
  page: PropTypes.number,
  fetchUsers: PropTypes.func,
}

