
import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import SweetAlert from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify'
import { Edit } from 'react-feather'
import PropTypes from 'prop-types'
import { updateVesselTypeApi } from '../../../common/Api/index'
import { Modal } from 'react-bootstrap'

const schema = yup
  .object()
  .shape({
    name: yup.string().max(30, '30 characters allowed !').required('Name is required !'),
  })
  .required()
const EditVesselType = (props) => {
  const [visible, setVisible] = useState(false)
  const { register, handleSubmit, formState, control, watch, setValue, getValues, reset } = useForm(
    {
      resolver: yupResolver(schema),
      mode: 'onChange',
    },
  )
  let { errors } = formState
  useEffect(() => {
    if (visible) {
      setValue('name', props.data?.name)
    }
  }, [])

  const onSubmit = (data) => {
    if (data !== '') {
      const params = { id: props.data.id }
      console.log('prams', params, data)
      updateVesselTypeApi(data, params).then(
        (res) => {
          console.log('res', res)
          setVisible(false)
          reset()
          props.fetchVesselTypes()
          toast.success('Vessel Type updated successfully', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
        },
        (err) => {
          console.log('err', err)
        },
      )
    } else {
      errors.showMessages()
    }
  }
  return (
    <>
      
        <Edit size={18} className="cursor-pointer ms-1" onClick={() => setVisible(!visible)} />

      <Modal size="lg" show={visible} onHide={() => setVisible(false)} backdrop="static">
        <form className="">
          <Modal.Header className="bg-primary-blue" closeButton >
            <Modal.Title className="">Update Vessel Type</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Name'}</label>
                  <input
                    className={errors.name ? 'form-control is-invalid' : 'form-control'}
                    type="text"
                    placeholder="Name*"
                    name="name"
                    {...register('name')}
                    defaultValue={props.data?.name}
                  />

                  <span className="text-danger">{errors.name?.message}</span>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* <CButton color="secondary" onClick={() => setVisible(false)}>
              Close
            </CButton> */}
            <button className="btn btn-primary-blue" type="submit" onClick={handleSubmit(onSubmit)}>
              {'Save Changes'}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}
export default EditVesselType

EditVesselType.propTypes = {
  props: PropTypes.object,
  data: PropTypes.object,
  fetchVesselTypes: PropTypes.func,
}
