import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

import {
  faGear,
  faUser,
  faArrowRightFromBracket
} from "@fortawesome/free-solid-svg-icons";


const NavbarComp = () => {
  let navigate = useNavigate()
  const loginData = JSON.parse(localStorage.getItem('loginData'));
  // console.log('loginData', loginData);

  function logout() {
    localStorage.clear()
    navigate("/applogin")
  }

  return (
    <Navbar expand="lg" id="navbar" style={{ marginLeft: "64px" }}>
      <Container fluid>
        {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
        <Navbar.Toggle aria-controls="navbar-light-example" />

        <Navbar.Collapse id="navbar-light-example">
          <Nav className="ms-auto">
            <NavDropdown
              id="custom-dropdown"
              title={<span style={{ color: 'black' }}>Welcome! <strong>{"Admin"}</strong> <FontAwesomeIcon style={{ color: '#8d8d8d' }} icon={faGear} size="lg" /></span>}
              menuVariant="light"
              align={'end'}
            >
              <NavDropdown.Item className="poppins-bold">Account</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => logout()}>
                <FontAwesomeIcon icon={faArrowRightFromBracket} size="sm" className="me-2" />
                  Logout
              </NavDropdown.Item>
              {/* <NavDropdown.Item
                className="cursor_pointer"
                onClick={() => {
                  navigate('/easyrest/resetpassword', { replace: true })
                }}
              >
                <FontAwesomeIcon icon={faUser} size="sm" className="me-2" />
                Reset Password
              </NavDropdown.Item> */}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default NavbarComp;
