import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import SweetAlert from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify'
import { Edit, Trash2, Eye, EyeOff } from 'react-feather'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { createSubscriptionsApi, fetchApplications } from '../../../common/Api'

function CreateSubscription(props) {
  const [visible, setVisible] = useState(false)
  const [appList, setAppList] = useState({ data: [], loading: false })

  const schema = yup
    .object()
    .shape({
      name: yup.string().max(30, '30 characters allowed !').required('Name is required !'),
      description: yup
        .string()
        .max(500, '500 characters allowed !')
        .required('Description is required !'),
      applicationid: yup.string().required('Please select an application'),
      status: yup.string().required('Please select an status'),

      usercount: yup.number().nullable().integer('Number allowed only!').required('Required !'),

      monthlysubscription: yup
        .mixed()
        .test('integerOrDecimal', 'Please enter a valid number', (value) => {
          // Regex to match integer or decimal numbers
          const regex = /^[0-9]+(\.[0-9]+)?$/
          return regex.test(value)
        })
        .required('Please select an option'),
      annualsubscription: yup
        .mixed()
        .test('integerOrDecimal', 'Please enter a valid number', (value) => {
          // Regex to match integer or decimal numbers
          const regex = /^[0-9]+(\.[0-9]+)?$/
          return regex.test(value)
        })
        .required('Please select an option'),
    })
    .required()
  const { register, handleSubmit, formState, control, watch, setValue, getValues, reset } = useForm(
    {
      resolver: yupResolver(schema),
      mode: 'onChange',
    },
  )
  let { errors } = formState

  useEffect(() => {
    if (visible) {
      getApplicationList()
      reset()
    }
  }, [visible])

  function getApplicationList() {
    setAppList({ ...appList, loading: true, data: [] })
    fetchApplications().then(
      (res) => {
        console.log('res', res)
        if (res.data.data.response.length > 0) {
          setAppList({ ...appList, loading: false, data: res.data.data.response })
        }
      },
      (err) => {
        setAppList({ ...appList, loading: false, data: [] })
        console.log('err', err)
      },
    )
  }

  const onSubmit = (data) => {
    if (data !== '') {
    
           if(data.applicationid==1){
            data.applicationname="Rest Hour"
           }else{
            data.applicationname="Opti Heat"

           }
      console.log('data', data)
      createSubscriptionsApi(data).then(
        (res) => {
          console.log('res', res)
          setVisible(false)
          reset()
          props.fetchSubscription(props.selectedApp, 1)
          toast.success('Subscription created successfully', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
        },
        (err) => {
          console.log('err', err)
        },
      )
    } else {
      errors.showMessages()
    }
  }

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <button className="btn btn-primary-blue" onClick={() => setVisible(!visible)}>
        Create Subscription
      </button>
      <Modal size="lg" show={visible} onHide={() => setVisible(false)} backdrop="static">
        <form className="">
          <Modal.Header className="bg-primary-blue" closeButton>
            <Modal.Title className="">Create Subscription</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Name'}</label>
                  <input
                    className={errors.name ? 'form-control is-invalid' : 'form-control'}
                    type="text"
                    placeholder="Plan Name*"
                    name="name"
                    {...register('name')}
                  />

                  <span className="text-danger">{errors.name?.message}</span>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Description'}</label>
                  <textarea
                    className={errors.description ? 'form-control is-invalid' : 'form-control'}
                    type="text"
                    placeholder="Plan Description*"
                    name="description"
                    {...register('description')}
                    rows="1"
                  ></textarea>

                  <span className="text-danger">{errors.description?.message}</span>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'User Count'}</label>
                  <input
                    className={errors.userCount ? 'form-control is-invalid' : 'form-control'}
                    type="number"
                    placeholder="User count*"
                    name="usercount"
                    {...register('usercount')}
                  />

                  <span className="text-danger">{errors.userCount?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Monthly Subscription'}</label>
                  <input
                    className={
                      errors.monthlySubscription ? 'form-control is-invalid' : 'form-control'
                    }
                    type="text"
                    placeholder="Enter Montly Amount*"
                    name="monthlysubscription"
                    {...register('monthlysubscription')}
                  />

                  <span className="text-danger">{errors.monthlySubscription?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Annual Subscription'}</label>
                  <input
                    className={
                      errors.annualSubscription ? 'form-control is-invalid' : 'form-control'
                    }
                    type="text"
                    placeholder="Enter Yearly Amount*"
                    name="annualsubscription"
                    {...register('annualsubscription')}
                  />

                  <span className="text-danger">{errors.annualSubscription?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Application'}</label>
                  <select
                    {...register('applicationid')}
                    className={errors.application ? 'form-control is-invalid' : 'form-control'}
                    id="application"
                  >
                    <option value={''}>Select</option>
                    {appList.data.map((app, appInd) => (
                      <option value={app.id} key={appInd}>
                        {app.name}
                      </option>
                    ))}
                  </select>

                  <span className="text-danger">{errors.application?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Status'}</label>

                  <select
                    {...register('status')}
                    className={errors.status ? 'form-control is-invalid' : 'form-control'}
                    id="substatus"
                  >
                    <option value={''}>Select</option>
                    <option value={true}>Active</option>
                    <option value={false}>Inactive</option>
                  </select>

                  <span className="text-danger">{errors.status?.message}</span>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* <CButton color="light" onClick={() => setVisible(false)}>
              Close
            </CButton> */}
            <button className="btn btn-primary-blue" type="submit" onClick={handleSubmit(onSubmit)}>
              {'Save'}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </React.Fragment>
  )
}

export default CreateSubscription
CreateSubscription.propTypes = {
  fetchSubscription: PropTypes.func,
  selectedApp: PropTypes.string,
}

