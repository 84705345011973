import React, { useState, useEffect } from 'react'
import {
  getCustSubscriptionApi,
  deleteCustSubsApi,
  getCustomerApi,
  updateCustSubscriptionApi,
} from '../../../common/Api/index'
import CreateCustSubs from './CreateCustSubs'
import { ToastContainer, toast } from 'react-toastify'
import EditCustSubs from './EditCustSubs'
import { Edit, Trash2, Eye, Archive } from 'react-feather'
import SweetAlert from 'sweetalert2'
import {
  calMaxPage,
  convertDate,
  convertDateYYMMDD,
} from '../../../common/Functions/CommonFunctions'
import { Typeahead } from 'react-bootstrap-typeahead'

import { Spinner,OverlayTrigger,Tooltip, Pagination } from 'react-bootstrap'

function CustomerSubscription() {
  const loginData = JSON.parse(localStorage.getItem('loginData'))

  const [custSubList, setCustSubList] = useState({ loading: true, data: [] })
  const [custList, setCustList] = useState({ loading: false, data: [] })
  const [selCust, setSelCust] = useState('')
  const [startId, setStartId] = useState(null)
  const [hasMore, setHasMore] = useState(false)

  const [page, setPage] = useState(1)
  const [maxPageAndTotalCount, setMaxPageAndTotalCount] = useState({ maxPage: '', totalCount: '' })
  const [srNo, setSrNo] = useState(0)
  useEffect(() => {
    fetchList(null, page)
    fetchCustomers()
  }, [])

  function fetchList(customer, val_page) {
    // console.log('selectedCust.toString().length()', selectedCust.toString().length)

    // setHasMore(false)
    // if (para3 == 'remPosition') {
    //   setCustSubList({ ...custSubList, loading: true, data: [] })
    // }
    // if (para3 == 'reset') {
    //   setCustSubList({ ...custSubList, loading: true, data: [] })
    // } else {
    //   setCustSubList((prevState) => ({ ...prevState, loading: true }))
    //   setHasMore(true)
    // }

    let params = { offset: val_page - 1, limit: 10 }

    if (customer !== null) {
      params.customer_name = customer
    }

    // if(val_page){
    //   params.offset=val_page
    //  }

    console.log('paramss', params)
    getCustSubscriptionApi(params).then(
      (res) => {
        console.log('res', res)
        if (res.data.data.response.results.length > 0) {
          // setCustSubList({ ...custSubList, loading: false, data: res.data.data.response.items })
          setCustSubList((prevState) => ({
            data: res.data.data.response.results,
            loading: false,
          }))

          setMaxPageAndTotalCount({
            ...maxPageAndTotalCount,
            maxPage: calMaxPage(res.data.data.response.count.totalData),
            totalCount: res.data.data.response.count.totalData,
          })
        } else {
          // setCustSubList({ ...custSubList, loading: false, data: [] })
          setCustSubList((prevState) => ({
            data: [],
            loading: false,
          }))
        }

        // if (res.data.data.response.startId !== null) {
        //   console.log(res.data.data.response.startId.id)
        //   setStartId(res.data.data.response.startId.id)
        //   setHasMore(true)
        // } else {
        //   setStartId(null)
        //   setHasMore(false)
        //   console.log('inside else')
        // }
      },
      (err) => {
        console.log('err', err)
        setCustSubList({ ...custSubList, loading: false, data: [] })
        toast.error('Something went wrong !', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      },
    )
  }

  function fetchCustomers() {
    setCustList({ loading: true, data: [] })

    getCustomerApi().then(
      (res) => {
        console.log('res', res)
        if (res.data.data.response.results.length > 0) {
          setCustList({ loading: false, data: res.data.data.response.results })
        } else {
          setCustList({ loading: false, data: [] })
        }
      },
      (err) => {
        console.log('err', err)
        setCustList({ loading: false, data: [] })
      },
    )
  }

  function delCustSubs(val) {
    SweetAlert.fire({
      title: 'Are you sure to delete subscription? \n',
      text: 'Once deleted, you will not be able to recover this record !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        console.log(val)
        deleteCustSubsApi({ id: val }).then(
          (res) => {
            console.log('res', res)
            toast.success('Customer subscription deleted successfully', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            })
            fetchList(null, 1)
          },
          (err) => {
            console.log('err', err)
            toast.error('Something went wrong !', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            })
          },
        )
      }
    })
  }

  function softDelete(data) {
    console.log('data', data)

    const reqPayload = {
      startdate: convertDateYYMMDD(data.startdate, '-'),
      enddate: convertDateYYMMDD(data.enddate, '-'),
      status: data.status,
      subscriptionpurchasedate: convertDateYYMMDD(data.subscriptionpurchasedate, '-'),
      paymentstatus: JSON.parse(data.paymentstatus),
      isactive: false,
      lisencekey: data.lisencekey,
      customer: data.customer,
      applicationid: data.applicationid,
      subscription: data.subscription.id,
    }

    SweetAlert.fire({
      title: `Are you sure to delete subscription for ${data.customer_name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        updateCustSubscriptionApi(reqPayload, { id: data.id }).then(
          (res) => {
            console.log('res', res)
            toast.success('Customer subscription deleted successfully', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            })
            fetchList(null, 1)
          },
          (err) => {
            console.log('err', err)
            toast.error('Something went wrong !', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            })
          },
        )
      }
    })
  }

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="customerSubscription">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header bg-primary-blue" style={{ fontSize: '20px' }}>
                Customer Subscription
              </div>
              <div className="card-body">
                <div className="row">
                  {/* <div className="col-12 col-md-3">
                    <label className="poppins-bold">Search By Customer</label>
                    <Typeahead
                      name="subscription"
                      id="basic-typeahead"
                      labelKey="name"
                      multiple={false}
                      onChange={(e) => {
                        //console.log(e)
                        if (e.length > 0) {
                          setSelCust(e.name)
                          fetchList(e[0].name)
                        } else {
                          setSelCust()
                          fetchList()
                        }
                      }}
                      options={custList.data}
                      isLoading={custList.loading}
                      placeholder="Select"
                    />
                    <select
                        onChange={(e) => {
                            fetchList(e.target.value)
                        }}
                        className={'form-control'}
                        id="application"
                      >
                        <option value={''}>Select</option>
                        {custList.data.map((app,i) => (
                          <option value={app.name} key={i}>
                            {app.name}
                          </option>
                        ))}
                      </select>
                  </div> */}
                  <div className="d-flex justify-content-end">
                    {/* <label className="poppins-bold invisible">Search By Customer</label> */}
                    <CreateCustSubs fetchList={fetchList} />
                  </div>
                  <div className="col-12 mt-4">
                    {custSubList.loading == true ? (
                      <Spinner animation="border" variant="primary" />
                    ) : custSubList.data.length > 0 ? (
                      <div className="table-responsive " style={{ maxHeight: '70vh' }}>
                        <table className="table table-striped table-bordered">
                          <thead className="thead-light">
                            <tr style={{ position: 'sticky', top: '0', background: 'white' }}>
                              <th scope="col">{'Sr.No'}</th>
                              <th scope="col">{'Application Id'}</th>
                              <th scope="col">{'Customer'}</th>
                              <th scope="col" style={{ width: '120px' }}>
                                {'Start Date'}
                              </th>
                              <th scope="col" style={{ width: '120px' }}>
                                {'End Date'}
                              </th>
                              <th scope="col">{'Lisence Key'}</th>
                              <th scope="col">{'Status'}</th>
                              <th scope="col">{'Subscription Purchase Date'}</th>
                              <th scope="col">{'Action'}</th>
                            </tr>
                          </thead>

                          {custSubList.data.map((val, ind) => (
                            <tbody key={ind}>
                              <tr>
                                <td>{ind + 1 + srNo}</td>
                                <td>{val.applicationid}</td>
                                <td>{val.customer_name}</td>
                                <td>{convertDate(new Date(val.startdate), '-')}</td>
                                <td>{convertDate(new Date(val.enddate), '-')}</td>
                                <td>{val.lisencekey}</td>
                                <td>{val.isactive ? 'Active' : 'Not Active'}</td>
                                <td>{convertDate(new Date(val.subscriptionpurchasedate), '-')}</td>
                                <td>
                                  <EditCustSubs fetchList={fetchList} data={val} page={page} />

                                  <OverlayTrigger
                                   placement="bottom"
                                   delay={{ show: 250, hide: 400 }}
                                   overlay={<Tooltip id="button-tooltip-1">Permanant Delete</Tooltip>}
                                  >

                                
                                    <Trash2
                                      size={18}
                                      className="cursor-pointer ms-1"
                                      onClick={() => delCustSubs(val.id)}
                                    />
                                 </OverlayTrigger>
                                 
                                 <OverlayTrigger
                                 placement="bottom"
                                 delay={{ show: 250, hide: 400 }}
                                 overlay={<Tooltip id="button-tooltip-1">Temporary Delete</Tooltip>}
                                 >

                               
                                    <Archive
                                      className="cursor-pointer"
                                      size={18}
                                      onClick={() => softDelete(val)}
                                    />
                                      </OverlayTrigger>
                                 
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      </div>
                    ) : (
                      <p className="m-0 text-center text-danger">{'No Data Found !'}</p>
                    )}
                    {custSubList.data.length > 0 ? (
                                        <div className="mt-4">
                                            <Pagination
                                                className=" cursor_pointer justify-content-end pagination-primary"
                                                aria-label="Page navigation example"
                                            >
                                                <Pagination.Item
                                                    onClick={() => {
                                                      fetchCustomers(page - 1)
                                                        setPage((prevP) => prevP - 1)
                                                        setSrNo((prevC) => prevC - 10)
                                                    }}
                                                    disabled={page === 1 ? true : false}
                                                >
                                                    Previous
                                                </Pagination.Item>
                                                <Pagination.Item>{page}</Pagination.Item>
                                                <Pagination.Item
                                                    onClick={() => {
                                                      fetchCustomers(page + 1)
                                                        setPage((prevP) => prevP + 1)
                                                        setSrNo((prevC) => prevC + 10)
                                                    }}
                                                    disabled={
                                                        page === maxPageAndTotalCount.maxPage || maxPageAndTotalCount.maxPage === 0
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    Next
                                                </Pagination.Item>
                                            </Pagination>
                                            <div>
                                                <div className="d-flex justify-content-end">
                                                    <p
                                                        className="mb-0"
                                                        style={{ fontSize: '14px' }}
                                                    >{`${page} / ${maxPageAndTotalCount.maxPage} pages`}</p>
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                    <p
                                                        className=""
                                                        style={{ fontSize: '14px' }}
                                                    >{`Total Records: ${maxPageAndTotalCount.totalCount}`}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CustomerSubscription
