import React, { useState, useEffect } from 'react'
import { Edit, Trash2, Eye, EyeOff } from 'react-feather'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import SweetAlert from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify'
import {
  getSubscriptionsApi,
  getCustomerApi,
  createCustSubscriptionApi,
  createCustAdminApi,
} from '../../../common/Api/index'
import { Typeahead } from 'react-bootstrap-typeahead'
import DatePicker from 'react-datepicker'
import { convertDateYYMMDD } from '../../../common/Functions/CommonFunctions'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'

const schema = yup
  .object()
  .shape({
    subscription: yup.array().min(1, 'Required').required('Subscription is required !'),
    customer: yup.array().min(1, 'Required').required('Customer is required !'),
    startdate: yup.date().required('Please select a date'),
    endDate: yup.date().required('Please select a date'),
    subscriptionPurchaseDate: yup.date().required('Please select a date'),
    paymentstatus: yup.string().required('Required !'),
    lisencekey: yup.string().required('Required !'),
    status: yup.string().required('Please select an status'),
  })
  .required()

function CreateCustSubs(props) {
  const [visible, setVisible] = useState(false)
  const [subList, setSubList] = useState({ data: [], loading: false })
  const [custList, setCustList] = useState({ data: [], loading: false })

  const { register, handleSubmit, formState, control, watch, setValue, getValues, reset } = useForm(
    {
      resolver: yupResolver(schema),
      mode: 'onChange',
    },
  )

  const selStartDate = watch('startdate')

  let { errors } = formState

  useEffect(() => {
    if (visible == true) {
      fetchSubs()
      getCustomers()
    }
  }, [visible])

  function getCustomers() {
    getCustomerApi().then(
      (res) => {
        console.log('res', res)
        if (res.data.data.response.results.length > 0) {
          //console.log('res.data.data.response', res.data.response)
          setCustList({ data: res.data.data.response.results, loading: false })
        } else {
          setCustList({ data: [], loading: false })
        }
      },
      (err) => {
        console.log('err', err)
        setCustList({ ...custList, data: [], loading: false })
        toast.error('Failed fetching customer list !', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      },
    )
  }

  function fetchSubs() {
    setSubList({ data: [], loading: true })
    getSubscriptionsApi().then(
      (res) => {
        console.log('res', res)
        if (res.data.data.response.results.length > 0) {
          setSubList({ data: res.data.data.response.results, loading: false })
        } else {
          setSubList({ data: [], loading: false })
        }
      },
      (err) => {
        console.log('err', err)
        setSubList({ data: [], loading: false })
        toast.error('Failed fetching subscription list !', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      },
    )
  }

  const onSubmit = (data) => {
    console.log('data', data)
    if (data !== '') {
      const reqPayload = {
        startdate: convertDateYYMMDD(data.startdate, '-'),
        enddate: convertDateYYMMDD(data.endDate, '-'),
        status: data.status,
        subscriptionpurchasedate: convertDateYYMMDD(data.subscriptionPurchaseDate, '-'),
        paymentstatus: JSON.parse(data.paymentstatus),
        isActive: true,
        lisencekey: data.lisencekey,
        customer: data.customer[0].id,
        subscription: data.subscription[0].id,
        applicationid: data.subscription[0].applicationid,
      }
      console.log('reqPayload', reqPayload)
      createCustSubscriptionApi(reqPayload).then(
        (res) => {
          console.log('res', res)
          reset()
          setVisible(false)
          toast.success('Customer subscription created successfully !', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
          props.fetchList(null, 1)
          console.log('data.customer[0].id', data.customer[0].id)
          console.log('data.subscription[0].applicationId', data.subscription[0].applicationid)
          createCustAdminApi({
            id: data.customer[0].id,
            applicationId: data.subscription[0].applicationid,
          }).then(
            (res) => {
              console.log('res', res)
          
            },
            (err) => {
              console.log('err', err)
            },
          )
        },
        (err) => {
          console.log('err', err)
          toast.error('Something went wrong !', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
        },
      )
    } else {
      errors.showMessages()
    }
  }

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <button className="btn btn-primary-blue" onClick={() => setVisible(!visible)}>
        Assign Subscription
      </button>

      <Modal size="lg" show={visible} onHide={() => setVisible(false)} backdrop="static">
        <form className="">
          <Modal.Header className="bg-primary-blue" closeButton>
            <Modal.Title className="">Create Customer Subscription</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Customer'}</label>
                  <Controller
                    name="customer"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        name="customer"
                        id="basic-typeahead"
                        labelKey="name"
                        multiple={false}
                        options={custList.data}
                        isLoading={custList.loading}
                        placeholder="Select"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.customer ? true : false}
                      />
                    )}
                  />
                  <span className="text-danger">{errors.customer?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Subscription'}</label>
                  <Controller
                    name="subscription"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        name="subscription"
                        id="basic-typeahead"
                        labelKey="name"
                        multiple={false}
                        options={subList.data}
                        isLoading={subList.loading}
                        placeholder="Select"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.subscription ? true : false}
                      />
                    )}
                  />
                  <span className="text-danger">{errors.subscription?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Start Date'}</label>

                  <Controller
                    name="startdate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        selected={field.value}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select a date"
                        minDate={new Date()}
                        onChange={(date) => field.onChange(date)}
                        className={errors.startdate ? 'is-invalid form-control' : 'form-control'}
                      />
                    )}
                  />
                  <span className="text-danger">{errors.startdate?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'End Date'}</label>

                  <Controller
                    name="endDate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        selected={field.value}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select a date"
                        onChange={(date) => field.onChange(date)}
                        minDate={selStartDate}
                        className={errors.endDate ? 'is-invalid form-control' : 'form-control'}
                      />
                    )}
                  />
                  <span className="text-danger">{errors.endDate?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Purchase Date'}</label>

                  <Controller
                    name="subscriptionPurchaseDate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        selected={field.value}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select a date"
                        onChange={(date) => field.onChange(date)}
                        className={
                          errors.subscriptionPurchaseDate
                            ? 'is-invalid form-control'
                            : 'form-control'
                        }
                        isInvalid={errors.subscriptionPurchaseDate ? true : false}
                      />
                    )}
                  />
                  <span className="text-danger">{errors.subscriptionPurchaseDate?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Payment Status'}</label>

                  <select
                    {...register('paymentstatus')}
                    className={errors.paymentstatus ? 'form-control is-invalid' : 'form-control'}
                    id="paymentstatus"
                  >
                    <option value={''}>Select</option>
                    <option value={true}>Done</option>
                    <option value={false}>Pending</option>
                  </select>

                  <span className="text-danger">{errors.paymentstatus?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'License'}</label>

                  <input
                    className={errors.lisencekey ? 'form-control is-invalid' : 'form-control'}
                    type="text"
                    placeholder="License*"
                    name="lisencekey"
                    {...register('lisencekey')}
                  />
                  <span className="text-danger">{errors.lisencekey?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Status'}</label>

                  <select
                    {...register('status')}
                    className={errors.status ? 'form-control is-invalid' : 'form-control'}
                    id="substatus"
                  >
                    <option value={''}>Select</option>
                    <option value={true}>Active</option>
                    <option value={false}>Inactive</option>
                  </select>

                  <span className="text-danger">{errors.status?.message}</span>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* <CButton color="light" onClick={() => setVisible(false)}>
              Close
            </CButton> */}
            <button className="btn btn-primary-blue" type="submit" onClick={handleSubmit(onSubmit)}>
              {'Save'}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </React.Fragment>
  )
}

CreateCustSubs.propTypes = {
  selCust: PropTypes.array,
  fetchList: PropTypes.func,
}

export default CreateCustSubs
