import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import SweetAlert from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify'
import { Edit, Trash2, Eye, EyeOff, Archive } from 'react-feather'
import CreateSubscription from './CreateSubscription'
import EditSubscription from './EditSubscription'
import { Pagination, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { deleteSubscriptionsApi, fetchApplications, getSubscriptionsApi, updateSubscriptionsApi } from '../../../common/Api'
import { calMaxPage } from '../../../common/Functions/CommonFunctions'

function SubscriptionMaster() {
    const loginData = JSON.parse(localStorage.getItem('loginData'))
    const [subsList, setSubsList] = useState({ loading: true, data: [] })
    const [appList, setAppList] = useState({ data: [], loading: false })
    const [selectedApp, setSelApp] = useState('')
    const [startId, setStartId] = useState(null)
    const [hasMore, setHasMore] = useState(false)
    const [page, setPage] = useState(1)
    const [maxPageAndTotalCount, setMaxPageAndTotalCount] = useState({ maxPage: '', totalCount: '' })
    const [srNo, setSrNo] = useState(0)

    useEffect(() => {
        getApplicationList()
        fetchSubscription(null, 1)
    }, [])

    function getApplicationList() {
        setAppList({ ...appList, loading: true, data: [] })
        fetchApplications().then(
            (res) => {
                console.log('res', res)
                if (res.data.data.response.length > 0) {
                    setAppList({ ...appList, loading: false, data: res.data.data.response })
                }
            },
            (err) => {
                setAppList({ ...appList, loading: false, data: [] })
                console.log('err', err)
            },
        )
    }

    function fetchSubscription(appid = null, val_page) {
        // setHasMore(false)
        // if (para3 == 'remPosition') {
        //   setSubsList({ ...subsList, loading: true, data: [] })
        // }
        // if (para3 == 'reset') {
        //   setSubsList({ ...subsList, loading: true, data: [] })
        // } else {
        //   setSubsList((prevState) => ({ ...prevState, loading: true }))
        //   setHasMore(true)
        // }

        let params = { limit: 10, offset: val_page - 1 }

        if (appid !== null) {
            params.applicationId = appid
        }
        //  if(val_page){
        //   params.offset=val_page
        //  }

        getSubscriptionsApi(params).then(
            (res) => {
                console.log('res', res.data)
                if (res.data.data.response.results.length > 0) {
                    setSubsList({ data: res.data.data.response.results, loading: false })

                    setMaxPageAndTotalCount({
                        ...maxPageAndTotalCount,
                        maxPage: calMaxPage(res.data.data.response.count.totalData),
                        totalCount: res.data.data.response.count.totalData,
                    })

                    // setSubsList({ ...subsList, loading: false, data: res.data.data.response.items })
                } else {
                    setSubsList({ ...subsList, loading: false, data: [] })
                }

                // if (res.data.data.response.startId !== null) {
                //   console.log(res.data.data.response.startId.created)
                //   setStartId(res.data.data.response.startId.created)
                //   setHasMore(true)
                // } else {
                //   setStartId(null)
                //   setHasMore(false)
                //   console.log('inside else')
                // }
            },
            (err) => {
                console.log('err', err)
                toast.error('Something went wrong !', {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                })
                setSubsList({ loading: false, data: [] })
            },
        )
    }

    function delSubscription(val) {
        SweetAlert.fire({
            title: 'Are you sure to delete? \n' + val.name,
            text: 'Once deleted, you will not be able to recover this record !',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                // Firing delete api
                console.log('val', val)

                console.log('val.id', val)
                deleteSubscriptionsApi({ id: val.id }).then(
                    (res) => {
                        console.log('res', res)

                        toast.success('Subscription deleted successfully', {
                            position: 'top-right',
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'light',
                        })
                        fetchSubscription(selectedApp, 1)
                    },
                    (err) => {
                        console.log('err', err)
                        toast.error('Something went wrong !', {
                            position: 'top-right',
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'light',
                        })
                    },
                )
            }
        })
    }

    function softDelete(data) {
        console.log('data', data)
        data.isactive = false

        SweetAlert.fire({
            title: 'Are you sure to delete? \n' + data.name,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                // Firing delete api
                updateSubscriptionsApi(data, { id: data.id }).then(
                    (res) => {
                        console.log('res', res)
                        toast.success('Subscription deleted successfully !', {
                            position: 'top-right',
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'light',
                        })
                        fetchSubscription(selectedApp, 1)
                    },
                    (err) => {
                        console.log('err', err)
                        toast.error('Something went wrong !', {
                            position: 'top-right',
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'light',
                        })
                    },
                )
            }
        })
    }

    return (
        <React.Fragment>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className="vdi_subscriptionplans mb-4">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header bg-primary-blue" style={{ fontSize: '20px' }}>
                                    Subscription Management
                                </div>
                                <div className="card-body">
                                    <div className="row mb-4">
                                        <div className="col-12 col-md-4 form-group">
                                            <label className="poppins-bold">{'Search By Application'}</label>
                                            <select
                                                onChange={(e) => {
                                                    setSelApp(e.target.value)
                                                    fetchSubscription(e.target.value, 1)
                                                }}
                                                className={'form-control'}
                                                id="application"
                                            >
                                                <option value={''}>Select Application</option>
                                                {appList.data.map((app, i) => (
                                                    <option value={app.id} key={i}>
                                                        {app.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-12 col-md-4 ">

                                            <label className="poppins-bold invisible">{'Select Application'}</label>
                                            <CreateSubscription
                                                fetchSubscription={fetchSubscription}
                                                selectedApp={selectedApp}
                                            />

                                        </div>
                                    </div>

                                    {subsList.loading ? (
                                        <Spinner animation="border" variant="primary" />) : subsList.data.length > 0 ? (
                                            <div
                                                className="table-responsive "
                                                style={{ maxHeight: '60vh', overflowX: 'auto' }}
                                            >
                                                <table className="table table-striped table-bordered">
                                                    <thead>
                                                        <tr
                                                            className="poppins-bold"
                                                            style={{ position: 'sticky', top: '0', background: 'white' }}
                                                        >
                                                            <th scope="col">{'SR.No'}</th>
                                                            <th scope="col">{'Name'}</th>
                                                            <th scope="col">{'Description'}</th>
                                                            <th scope="col">{'Application Id'}</th>
                                                            <th scope="col">{'Application Name'}</th>
                                                            <th scope="col">{'No.OfUsers'}</th>
                                                            <th scope="col">{'MonthlySubscription'}</th>
                                                            <th scope="col">{'AnnualSubscription'}</th>
                                                            <th scope="col">{'Action'}</th>
                                                        </tr>
                                                    </thead>

                                                    {subsList.data.map((val, ind) => (
                                                        <tbody key={ind}>
                                                            <tr>
                                                                <td>{ind + 1 + srNo}</td>
                                                                <td>{val.name}</td>
                                                                <td>{val.description}</td>
                                                                <td>{val.applicationid == 1 ? 'Rest Hour' : 'Opti Heat'}</td>
                                                                <td>{val.name}</td>
                                                                <td>{val.usercount}</td>
                                                                <td>{val.monthlysubscription}</td>
                                                                <td>{val.annualsubscription}</td>
                                                                <td>
                                                                    <EditSubscription
                                                                        fetchSubscription={fetchSubscription}
                                                                        selectedApp={selectedApp}
                                                                        page={page}
                                                                        data={val}
                                                                    />

                                                                    <OverlayTrigger
                                                                        placement="bottom"
                                                                        delay={{ show: 250, hide: 400 }}
                                                                        overlay={<Tooltip id="button-tooltip-1">Permanant Delete</Tooltip>}
                                                                    >
                                                                        <Trash2
                                                                            size={18}
                                                                            className="cursor-pointer ms-1"
                                                                            onClick={() => delSubscription(val)}
                                                                        />
                                                                    </OverlayTrigger>

                                                                    <OverlayTrigger
                                                                     placement="bottom"
                                                                     delay={{ show: 250, hide: 400 }}
                                                                     overlay={<Tooltip id="button-tooltip-1">Temporary Delete</Tooltip>}
                                                                    >


                                                                        <Archive
                                                                            className="cursor-pointer ms-1"
                                                                            size={18}
                                                                            onClick={() => softDelete(val)}
                                                                        />
                                                                    </OverlayTrigger>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    ))}
                                                </table>
                                            </div>
                                        ) : (
                                        <p className="m-0 text-center text-danger">{'No Data Found !'}</p>
                                    )}

                                    {subsList.data.length > 0 ? (
                                        <div className="mt-4">
                                            <Pagination
                                                className=" cursor_pointer justify-content-end pagination-primary"
                                                aria-label="Page navigation example"
                                            >
                                                <Pagination.Item
                                                    onClick={() => {
                                                        fetchSubscription(page - 1)
                                                        setPage((prevP) => prevP - 1)
                                                        setSrNo((prevC) => prevC - 10)
                                                    }}
                                                    disabled={page === 1 ? true : false}
                                                >
                                                    Previous
                                                </Pagination.Item>
                                                <Pagination.Item>{page}</Pagination.Item>
                                                <Pagination.Item
                                                    onClick={() => {
                                                        fetchSubscription(page + 1)
                                                        setPage((prevP) => prevP + 1)
                                                        setSrNo((prevC) => prevC + 10)
                                                    }}
                                                    disabled={
                                                        page === maxPageAndTotalCount.maxPage || maxPageAndTotalCount.maxPage === 0
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    Next
                                                </Pagination.Item>
                                            </Pagination>
                                            <div>
                                                <div className="d-flex justify-content-end">
                                                    <p
                                                        className="mb-0"
                                                        style={{ fontSize: '14px' }}
                                                    >{`${page} / ${maxPageAndTotalCount.maxPage} pages`}</p>
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                    <p
                                                        className=""
                                                        style={{ fontSize: '14px' }}
                                                    >{`Total Records: ${maxPageAndTotalCount.totalCount}`}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SubscriptionMaster
