import React, { useState, useEffect } from 'react'
import { Edit, Trash2, Eye, EyeOff, Archive } from 'react-feather'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import SweetAlert from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify'
import axios from 'axios'
import EditCustomer from './EditCustomer'

import { calMaxPage } from '../../../common/Functions/CommonFunctions'
import { DelCustomerApi, createCustApi, createLogoApi, getCountriesApi, getCustomerApi, updateCustApi } from '../../../common/Api'
import { Modal, Pagination, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap'

const schema = yup
  .object()
  .shape({
    name: yup.string().max(30, '30 characters allowed !').required('Name is required !'),
    email: yup.string().required('Email is required !').email('Please enter valid email !'),
    username: yup.string().required('Username is required !'),
    password: yup
      .string()
      .required('Password is required !')
      .min(6, 'Minimum 6 characters required !')
      .max(16, 'Maximum 16 characters allowed !')
      .matches(/^((?!\s).)*$/, 'Space not allowed'),
    website: yup.string().required('Website is required !'),
    contactnumber: yup.string().matches(/^\d+$/, 'Required').max(15, '15 characters allowed !'),
    address: yup.string().nullable().required('Address is required !'),
    // country: yup.string().nullable().required('Country is required !'),
    customertype: yup
      .string()
      .nullable()
      .required('Customer type is required !')
      .matches(/^((?!Select).)*$/, 'Please select valid option'),
    logo: yup.mixed().test('fileSize', 'The file is too large', (value) => {
      if (!value.length) return true // attachment is optional
      return value[0].size <= 5000000
    }),
  })
  .required()

function CustomerMaster() {
  let navigate = useNavigate()
  const [visible, setVisible] = useState(false)
  const [users, setUsers] = useState({ data: [], loading: false })
  const [countries, setCountries] = useState([])
  const [showPassword, setShowPassword] = useState(false)
  const [sameAsEmail, setSameAsEmail] = useState(false)
  const [page, setPage] = useState(1)
  const [maxPageAndTotalCount, setMaxPageAndTotalCount] = useState({ maxPage: '', totalCount: '' })
  const [srNo, setSrNo] = useState(0)


  const { register, handleSubmit, formState, control, watch, setValue, getValues, reset } = useForm(
    {
      resolver: yupResolver(schema),
      mode: 'onChange',
    },
  )
  let { errors } = formState

  useEffect(() => {
    // console.log('sameAsEmail', sameAsEmail)
    if (sameAsEmail) {
      const email = getValues('email')
      setValue('username', email)
    }
  }, [sameAsEmail])

  useEffect(() => {
    fetchUsers(page)
    getCountriesApi().then((res) => {
      let countries = res.data.data.response
      //console.log(countries)
      let countriesOption = countries.map((country, i) => {
        return (
          <option key={i} value={country.name}>
            {country.name}
          </option>
        )
      })
      setCountries(countriesOption)
    })
  }, [])

  const fetchUsers = (p) => {
    setUsers({ ...users, loading: true, data: [] })
    getCustomerApi({ limit: 10, offset: p - 1 }).then(
      (res) => {
        // console.log('res', res)
        if (res.data.data.response.results.length > 0) {
          setUsers({ ...users, loading: false, data: res.data.data.response.results })
          setMaxPageAndTotalCount({
            ...maxPageAndTotalCount,
            maxPage: calMaxPage(res.data.data.response.count.totalData),
            totalCount: res.data.data.response.count.totalData,
          })
        }
      },
      (err) => {
        console.log('err', err)
        toast.error('Something went wrong !', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
        setUsers({ ...users, loading: false, data: [] })
      },
    )
  }

  const onSubmit = (data) => {
    // data.adminemail = data.email
    data.signature = ''
    data.logo = data.logo[0]
    console.log('data', data)
    if (data !== '') {
      if (data.logo !== undefined) {
        createLogoApi({
          file_name: data.logo.name,
          folderName: 'logo',
        }).then(
          async (res) => {
            console.log('res', res)
            await axios.put(res.data.data.response.url, data.logo).then(
              (sres) => {
                console.log('sres', sres)
                data.logo = res.data.data.response.filename
                fireApi(data)
              },
              (serr) => {
                console.log('serr', serr)
                toast.error('Failed uploading saving logo!', {
                  position: 'top-right',
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
                })
                data.logo = ''
                fireApi(data)
              },
            )
          },
          (err) => {
            console.log('err', err)
          },
        )
      } else {
        delete data.logo
        fireApi(data)
      }
    } else {
      errors.showMessages()
    }
  }

  function fireApi(data) {
    createCustApi(data).then(
      (res) => {
        console.log('res', res)
        if (res.data.data.statusCode == 200) {
          setVisible(!visible)
          toast.success('Customer created successfully', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
          fetchUsers(page)
          reset()
        }
      },
      (err) => {
        console.log('err', err)
        toast.error('Something went wrong !', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      },
    )
  }

  function delCust(val) {
    SweetAlert.fire({
      title: 'Are you sure to delete? \n' + val.name,
      text: 'Once deleted, you will not be able to recover this record !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        DelCustomerApi({ id: val.id }).then(
          (res) => {
            console.log('res', res)
            toast.success('Customer deleted successfully', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            })
            fetchUsers(page)
          },
          (err) => {
            console.log('err', err)
            toast.error('Something went wrong !', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            })
          },
        )
      }
    })
  }

  function softDelete(data) {
    console.log('data', data)
    data.isactive = false

    SweetAlert.fire({
      title: 'Are you sure to delete? \n' + data.name,
      text: 'Once deleted, you will not be able to recover this record !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        updateCustApi(data, { id: data.id }).then(
          (res) => {
            console.log('res', res)
            toast.success('Customer deleted successfully', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            })
            fetchUsers(page)
          },
          (err) => {
            console.log('err', err)
            toast.error('Something went wrong !', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            })
          },
        )
      }
    })
  }

  return (
    <div className="mb-4">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="card">
        <div className="card-header bg-primary-blue" style={{ fontSize: '20px' }}>
          Customer List
        </div>
        <div className="card-body">
          <div className="d-flex justify-content-end">
            <button
              className="btn bg-primary-blue"
              onClick={() => {
                setVisible(true)
                setSameAsEmail(false)
                reset()
              }}
            >
              Create Customer
            </button>
          </div>

          <>
            <div className=" mt-4">
              {users.loading ? (
                <Spinner animation="border" variant="primary" />
              ) : users.data.length > 0 ? (
                <div className="table-responsive" style={{ maxHeight: '60vh', overflowX: 'auto' }}>
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr
                        className="poppins-bold"
                        style={{ position: 'sticky', top: '0', background: 'white' }}
                      >
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Username</th>
                        <th scope="col">Email</th>
                        <th scope="col">Contact</th>
                        <th scope="col">Address</th>
                        <th scope="col">Country</th>
                        <th scope="col">State</th>
                        <th scope="col">City</th>
                        <th scope="col">Website</th>
                        <th scope="col">Logo</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.data.map((user, index) => (
                        <tr key={user.id}>
                          <th scope="row">{index + 1 + srNo}</th>
                          <td>{user.name}</td>
                          <td>{user.username}</td>
                          <td>{user.email}</td>
                          <td>{user.contactnumber}</td>
                          <td>{user.address}</td>
                          <td>{user.country}</td>
                          <td>{user.state}</td>
                          <td>{user.city}</td>
                          <td>{user.website}</td>
                          <td>
                            {user.logo !== null ? (
                              <img src={user.logo} height={'60'} width={'60'} alt="logo" />
                            ) : (
                              'NA'
                            )}
                          </td>
                          <td>
                            <EditCustomer fetchUsers={fetchUsers} page={page} data={user} />

                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={<Tooltip id="button-tooltip-1">Permanant Delete</Tooltip>}
                            >

                              <Trash2
                                size={18}
                                className="cursor-pointer ms-1"
                                onClick={() => delCust(user)}
                              />

                            </OverlayTrigger>

                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={<Tooltip id="button-tooltip-1">Temporary Delete</Tooltip>}
                            >

                              <Archive
                                className="cursor-pointer"
                                size={18}
                                onClick={() => softDelete(user)}
                              />
                            </OverlayTrigger>

                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className="m-0 text-center text-danger">{'No Data Found !'}</p>
              )}
              {users.data.length > 0 ? (
                <div className="mt-4">
                  <Pagination
                    className=" cursor_pointer justify-content-end pagination-primary"
                    aria-label="Page navigation example"
                  >
                    <Pagination.Item
                      onClick={() => {
                        fetchUsers(page - 1)
                        setPage((prevP) => prevP - 1)
                        setSrNo((prevC) => prevC - 10)
                      }}
                      disabled={page === 1 ? true : false}
                    >
                      Previous
                    </Pagination.Item>
                    <Pagination.Item>{page}</Pagination.Item>
                    <Pagination.Item
                      onClick={() => {
                        fetchUsers(page + 1)
                        setPage((prevP) => prevP + 1)
                        setSrNo((prevC) => prevC + 10)
                      }}
                      disabled={
                        page === maxPageAndTotalCount.maxPage || maxPageAndTotalCount.maxPage === 0
                          ? true
                          : false
                      }
                    >
                      Next
                    </Pagination.Item>
                  </Pagination>
                  <div>
                    <div className="d-flex justify-content-end">
                      <p
                        className="mb-0"
                        style={{ fontSize: '14px' }}
                      >{`${page} / ${maxPageAndTotalCount.maxPage} pages`}</p>
                    </div>
                    <div className="d-flex justify-content-end">
                      <p
                        className=""
                        style={{ fontSize: '14px' }}
                      >{`Total Records: ${maxPageAndTotalCount.totalCount}`}</p>
                    </div>
                  </div>
                </div>
              ) : null}

            </div>
          </>
        </div>
      </div>
      <Modal size="lg" show={visible} onHide={() => setVisible(false)} backdrop="static">
        <form className="">
          <Modal.Header className="bg-primary-blue" closeButton>
            <Modal.Title className="">Create Customer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Name'}</label>
                  <input
                    className={errors.name ? 'form-control is-invalid' : 'form-control'}
                    type="text"
                    placeholder="Name*"
                    name="name"
                    {...register('name')}
                  />

                  <span className="text-danger">{errors.name?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Email'}</label>
                  <input
                    className={errors.email ? 'form-control is-invalid' : 'form-control'}
                    type="email"
                    name="email"
                    placeholder="Email Address*"
                    {...register('email')}
                  />

                  <span className="text-danger">{errors.email?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Username'}</label>
                  <input
                    className={errors.username ? 'form-control is-invalid' : 'form-control'}
                    type="text"
                    name="username"
                    placeholder="Username*"
                    {...register('username')}
                  />
                  <div className="form-group form-check">
                    <input
                      type="checkbox"
                      checked={sameAsEmail}
                      onChange={(e) => setSameAsEmail(e.target.checked)}
                      className="form-check-input"
                      id="exampleCheck1"
                    />
                    <label
                      style={{ fontSize: '13px', color: 'gray' }}
                      className="form-check-label"
                      htmlFor="exampleCheck1"
                    >
                      Same As Email
                    </label>
                  </div>
                  <span className="text-danger">{errors.username?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Password'}</label>
                  <div className="input-group">
                    <input
                      className={errors.password ? 'form-control is-invalid' : 'form-control'}
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Password*"
                      name="password"
                      {...register('password')}
                    />
                    <div className="input-group-append">
                      {showPassword ? (
                        <span className="input-group-text">
                          <EyeOff
                            size={18}
                            className="cursor-pointer my-1"
                            onClick={() => setShowPassword(false)}
                          />
                        </span>
                      ) : (
                        <span className="input-group-text">
                          <Eye
                            size={18}
                            className="cursor-pointer my-1"
                            onClick={() => setShowPassword(true)}
                          />
                        </span>
                      )}
                    </div>
                  </div>

                  <span className="text-danger">{errors.password?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Website'}</label>
                  <input
                    className={errors.website ? 'form-control is-invalid' : 'form-control'}
                    type="text"
                    placeholder="Website*"
                    name="website"
                    {...register('website')}
                  />

                  <span className="text-danger">{errors.website?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Customer Type'}</label>
                  <Controller
                    name="customertype"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        className={
                          errors.customertype
                            ? 'custom-select form-control is-invalid'
                            : 'custom-select form-control'
                        }
                      >
                        <option>{'--Select*--'}</option>
                        <option value={'Owner'}>Owner</option>
                        <option value={'Owner 1'}>Owner 1</option>

                        {/* {custTypeList.data.map((val, i) => (
                            <option defaultValue={val.name} key={i}>
                              {val.name}
                            </option>
                          ))} */}
                      </select>
                    )}
                  />

                  <span className="text-danger">{errors.customertype?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Address'}</label>

                  <textarea
                    className={errors.address ? 'form-control is-invalid' : 'form-control'}
                    placeholder="Address*"
                    name="address"
                    {...register('address')}
                    rows="1"
                  ></textarea>

                  <span className="text-danger">{errors.address?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Country'}</label>
                  <Controller
                    name="country"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        className={
                          errors.country
                            ? 'custom-select form-control is-invalid'
                            : 'custom-select form-control'
                        }
                      >
                        <option>{'--Select*--'}</option>
                        {countries}
                      </select>
                    )}
                  />

                  <span className="text-danger">{errors.country?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Contact Number'}</label>

                  <div className="form-group mb-2">
                    <input
                      className={errors.contactnumber ? 'form-control is-invalid' : 'form-control'}
                      type="number"
                      max={'15'}
                      placeholder="Contact*"
                      name="contactnumber"
                      {...register('contactnumber')}
                    />
                  </div>

                  <span className="text-danger">{errors.contactnumber?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'State'}</label>

                  <input
                    className={errors.state ? 'form-control is-invalid' : 'form-control'}
                    type="text"
                    placeholder="State*"
                    name="state"
                    {...register('state')}
                  />
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'City'}</label>
                  <input
                    className={errors.city ? 'form-control is-invalid' : 'form-control'}
                    type="text"
                    placeholder="City*"
                    name="city"
                    {...register('city')}
                  />
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">Logo</label>

                  <input
                    className={errors.logo ? 'form-control is-invalid' : 'form-control'}
                    type={'file'}
                    accept="image/*"
                    {...register('logo')}
                  />

                  <span className="text-danger">{errors.logo?.message}</span>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary-blue" type="submit" onClick={handleSubmit(onSubmit)}>
              {'Save'}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  )
}

export default CustomerMaster

