import React, { useState } from "react";
import SideNav, {
  Toggle,
  Nav,
  NavItem,
  NavIcon,
  NavText,
} from "@trendmicro/react-sidenav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faGear,
  faShip,
  faSliders,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import logo from '../../assets/images/project/vedampng.png'

function Sidebar() {
  const [toggle, setToggle] = useState(false);
  let navigate = useNavigate();
  let url = useLocation();
  //console.log("url", url);
  // let toggle = false;

  function reSize() {
    var screenWidth = window.innerWidth;

    if (screenWidth > 768) {
      // Add margin to the div for small screens
      var div1 = document.getElementById("root_body");
      var div2 = document.getElementById("navbar");
      if (toggle == true) {
        div1.style.marginLeft = "64px";
        div2.style.marginLeft = "64px";
      } else {
        div1.style.marginLeft = "240px";
        div2.style.marginLeft = "240px";
      }
    } else {
      // No margin needed for larger screens
    }
  }

  return (
    <SideNav
      onSelect={(selected) => {
        console.log(selected)
        navigate(selected);
      }}
      onToggle={() => {
        reSize();
        // toggle = !toggle;
        setToggle(!toggle);
      }}
    >
      <SideNav.Toggle />
      <div className={toggle ? "" : "text-center"}>
        <img src={logo} id="logo" className="p-1 bg-light" alt="logo" height={toggle ? 100 : 50} />
      </div>
      <SideNav.Nav defaultSelected={url.pathname}>
        <NavItem eventKey="/customers">
          <NavIcon>
            <FontAwesomeIcon style={{ color: '#e9e9e9' }} icon={faUser} size="lg" />
          </NavIcon>
          <NavText>Customer</NavText>
        </NavItem>
        <NavItem eventKey="/subscriptions">
          <NavIcon>
            <FontAwesomeIcon style={{ color: '#e9e9e9' }} icon={faGear} size="lg" />
          </NavIcon>
          <NavText>Subscriptions</NavText>
        </NavItem>
        <NavItem eventKey="/customersubscription">
          <NavIcon>
            <FontAwesomeIcon style={{ color: '#e9e9e9' }} icon={faSliders} size="lg" />
          </NavIcon>
          <NavText>Customer Subscription</NavText>
        </NavItem>
        <NavItem eventKey="/vesseltype">
          <NavIcon>
            <FontAwesomeIcon style={{ color: '#e9e9e9' }} icon={faShip} size="lg" />
          </NavIcon>
          <NavText>Vessel Type</NavText>
        </NavItem>
      </SideNav.Nav>
    </SideNav>
  );
}

export default Sidebar;
