import React, { useEffect } from 'react'

import CustomerMaster from '../Project Components/Superadmin/Customer/CustomerMaster';
import SubscriptionMaster from '../Project Components/Superadmin/Subscription/SubscriptionMaster'
import CustomerSubscription from '../Project Components/Superadmin/CustomerSubscription/CustomerSubscription'
import VesselTypeMaster from '../Project Components/Superadmin/Vessel Type/VesselTypeMaster'

// const CustomerMaster = React.lazy(() =>
//   import('../Project Components/Superadmin/Customer/CustomerMaster'),
// )
// const SubscriptionMaster = React.lazy(() =>
//   import('../Project Components/Superadmin/Subscription/SubscriptionMaster'),
// )
// const CustomerSubscription = React.lazy(() =>
//   import('../Project Components/Superadmin/CustomerSubscription/CustomerSubscription'),
// )
// const VesselTypeMaster=React.lazy(()=>
// import('../Project Components/Superadmin/Vessel Type/VesselTypeMaster')
// )

const routes = [
  { path: '/customers', name: 'CustomerMaster', element: CustomerMaster },
  { path: '/subscriptions', name: 'SubscriptionMaster', element: SubscriptionMaster },
  { path: '/customersubscription', name: 'CustomerSubscription', element: CustomerSubscription },
  { path: '/vesseltype', name:'VesselTypeMaster', element:VesselTypeMaster},
]

export default routes

