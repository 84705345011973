import React, { useState, Suspense } from "react";
import "./App.css";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-bootstrap-typeahead/css/Typeahead.css'

import { BrowserRouter, Routes, Route } from "react-router-dom";
import DefaultLayout from "./Project Components/DefaultLayout/DefaultLayout";
import Login from "./Project Components/Authentication/Login";
import ForgotPassword from "./Project Components/Authentication/ForgotPassword";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <React.Fragment>
      <BrowserRouter>
        <Suspense fallback={<p>loading</p>}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/applogin" element={<Login />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="*" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
      {/* <NavbarComp /> */}

      {/* <div
        id="main_content"
        style={{
          overflow: "hidden",
          marginLeft: "64px",
          height: "100vh",
          overflowY: "scroll",
        }}
      >
       
      </div> */}
    </React.Fragment>
  );
}

export default App;
