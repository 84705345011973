import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
//console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
const API_BASE_URL = process.env.REACT_APP_API_URL

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
})

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    // Retrieve the access token from wherever it is stored
    const accessToken = localStorage.getItem('token')

    // If there is an access token, add it to the request headers
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }

    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  },
)
axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => {
    const status = error.response ? error.response.status : null

    switch (status) {
      case 403:
        localStorage.clear()

        toast.error('Session expired !', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
        setTimeout(() => {
          window.location.href = '/applogin'
        }, [2000])
    }

    return Promise.reject(error)
  },
)

export default axiosInstance
