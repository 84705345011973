import React, { useEffect, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import routes from "../../Routes/routes";

function AppContent() {
  const token = localStorage.getItem("token");


  return (
    <Routes>
      {routes.map((route, idx) => {
        return (
          route.element && (
            <Route
              key={idx}
              path={route.path}
              exact={route.exact}
              name={route.name}
              element={<route.element />}
            />
          )
        );
      })}

      <Route
        path="*"
        element={
          token == null || token == undefined ? (
            <Navigate to="/" />
          ) : (
            <Navigate to="/easyrest/vessels" />
          )
        }
      />
    </Routes>
  );
}

export default AppContent;
