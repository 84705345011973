import React, { useState, useEffect } from 'react'
import { Edit, Trash2, Eye, EyeOff } from 'react-feather'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import SweetAlert from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify'
import {
  getSubscriptionsApi,
  getCustomerApi,
  updateCustSubscriptionApi,
} from '../../../common/Api/index'
import { Typeahead } from 'react-bootstrap-typeahead'
import DatePicker from 'react-datepicker'
import { convertDateYYMMDD } from '../../../common/Functions/CommonFunctions'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'

const schema = yup
  .object()
  .shape({
    subscription: yup.array().min(1, 'Required').required('Subscription is required !'),
    customer: yup.array().min(1, 'Required').required('Customer is required !'),
    startdate: yup.date().required('Please select a date'),
    enddate: yup.date().required('Please select a date'),
    subscriptionpurchasedate: yup.date().required('Please select a date'),
    paymentstatus: yup.string().required('Required !'),
    lisencekey: yup.string().required('Required !'),
    status: yup.string().required('Please select an status'),
  })
  .required()

function EditCustSubs(props) {
  const [visible, setVisible] = useState(false)
  const [subList, setSubList] = useState({ data: [], loading: false })
  const [custList, setCustList] = useState({ data: [], loading: false })

  const { register, handleSubmit, formState, control, watch, setValue, getValues, reset } = useForm(
    {
      resolver: yupResolver(schema),
      mode: 'onChange',
    },
  )

  const selStartDate = watch('startdate')

  let { errors } = formState

  useEffect(() => {
    if (visible == true) {
      reset()
      fetchSubs()
      getCustomers()
      console.log('props.data', props.data)
      setValue('startdate', new Date(props.data.startdate))
      setValue('enddate', new Date(props.data.enddate))
      setValue('subscriptionpurchasedate', new Date(props.data.subscriptionpurchasedate))
      setValue('lisencekey', props.data.lisencekey)
      setValue('paymentstatus', props.data.paymentstatus)
      setValue('status', JSON.parse(props.data.status))
    }
  }, [visible])

  function getCustomers() {
    setCustList({ data: [], loading: true })
    getCustomerApi({ limit: 1000, offset: 0 }).then(
      (res) => {
        console.log('res', res)
        if (res.data.data.response.results.length > 0) {
          //console.log('res.data.data.response', res.data.data.response)
          setCustList({ ...custList, data: res.data.data.response.results, loading: false })

          const fil = res.data.data.response.results.filter((val) => {
            return val.id == props.data.customer
          })
          console.log('fil', fil)
          setValue('customer', fil)
        } else {
          setCustList({ data: [], loading: false })
        }
      },
      (err) => {
        console.log('err', err)
        setCustList({ ...custList, data: [], loading: false })
        toast.error('Failed fetching customer list !', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      },
    )
  }

  function fetchSubs() {
    setSubList({ data: [], loading: true })
    getSubscriptionsApi().then(
      (res) => {
        console.log('res', res)
        if (res.data.data.response.results.length > 0) {
          setSubList({ ...subList, data: res.data.data.response.results, loading: false })
          const filSub = res.data.data.response.results.filter((val) => {
            return val.id == props.data.subscription.id
          })
          console.log('filSub', filSub)
          setValue('subscription', filSub)
        } else {
          setSubList({ data: [], loading: false })
        }
      },
      (err) => {
        console.log('err', err)
        setSubList({ ...subList, data: [], loading: false })
        toast.error('Failed fetching subscription list !', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      },
    )
  }

  const onSubmit = (data) => {
    console.log('data', data)
    if (data !== '') {
      const reqPayload = {
        startdate: convertDateYYMMDD(data.startdate, '-'),
        enddate: convertDateYYMMDD(data.enddate, '-'),
        status: data.status,
        subscriptionpurchasedate: convertDateYYMMDD(data.subscriptionpurchasedate, '-'),
        paymentstatus: JSON.parse(data.paymentstatus),
        isActive: true,
        lisencekey: data.lisencekey,
        customer: data.customer[0].id,
        applicationid: data.subscription[0].applicationid,
        subscription: data.subscription[0].id,
      }
      // console.log('reqPayload', reqPayload)
      //console.log('id', props.data.id);

      updateCustSubscriptionApi(reqPayload, {
        id: props.data.id,
      }).then(
        (res) => {
          console.log('res', res)
          toast.success('Customer subscription updated successfully !', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
          setVisible(false)
          props.fetchList(null, props.page)
        },
        (err) => {
          console.log('err', err)
          toast.error('Something went wrong !', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
        },
      )
    } else {
      errors.showMessages()
    }
  }

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      
        <Edit size={18} className="cursor-pointer " onClick={() => setVisible(!visible)} />
    

      <Modal size="lg" show={visible} onHide={() => setVisible(false)} backdrop="static">
        <form className="">
          <Modal.Header className="bg-primary-blue" closeButton>
            <Modal.Title className="">Update Customer Subscription</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Customer'}</label>

                  <Controller
                    name="customer"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead"
                        labelKey="name"
                        multiple={false}
                        options={custList.data}
                        isLoading={custList.loading}
                        placeholder="--Select*--"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        // defaultSelected={props.data === null ? [] : [props.data?.customer]}
                        isInvalid={errors.customer ? true : false}
                      />
                    )}
                  />

                  <span className="text-danger">{errors.customer?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Subscription'}</label>
                  <Controller
                    name="subscription"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        name="subscription"
                        id="basic-typeahead"
                        labelKey="name"
                        multiple={false}
                        options={subList.data}
                        isLoading={subList.loading}
                        placeholder="Select"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.subscription ? true : false}
                      />
                    )}
                  />
                  <span className="text-danger">{errors.subscription?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Start Date'}</label>

                  <Controller
                    name="startdate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        selected={field.value}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select a date"
                        minDate={new Date()}
                        onChange={(date) => field.onChange(date)}
                        className={errors.startdate ? 'is-invalid form-control' : 'form-control'}
                      />
                    )}
                  />
                  <span className="text-danger">{errors.startdate?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'End Date'}</label>

                  <Controller
                    name="enddate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        selected={field.value}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select a date"
                        onChange={(date) => field.onChange(date)}
                        minDate={selStartDate}
                        className={errors.enddate ? 'is-invalid form-control' : 'form-control'}
                      />
                    )}
                  />
                  <span className="text-danger">{errors.enddate?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Purchase Date'}</label>

                  <Controller
                    name="subscriptionpurchasedate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        selected={field.value}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select a date"
                        onChange={(date) => field.onChange(date)}
                        className={
                          errors.subscriptionpurchaseDate
                            ? 'is-invalid form-control'
                            : 'form-control'
                        }
                        isInvalid={errors.subscriptionpurchasedate ? true : false}
                      />
                    )}
                  />
                  <span className="text-danger">{errors.subscriptionpurchasedate?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Payment Status'}</label>

                  <select
                    {...register('paymentstatus')}
                    className={errors.paymentstatus ? 'form-control is-invalid' : 'form-control'}
                    id="paymentstatus"
                  >
                    <option value={''}>Select</option>
                    <option value={true}>Done</option>
                    <option value={false}>Pending</option>
                  </select>

                  <span className="text-danger">{errors.paymentstatus?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'License Key'}</label>

                  <input
                    className={errors.lisencekey ? 'form-control is-invalid' : 'form-control'}
                    type="text"
                    placeholder="License*"
                    name="lisencekey"
                    {...register('lisencekey')}
                  />
                  <span className="text-danger">{errors.lisencekey?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Status'}</label>

                  <select
                    {...register('status')}
                    className={errors.status ? 'form-control is-invalid' : 'form-control'}
                    id="substatus"
                  >
                    <option value={''}>Select</option>
                    <option value={true}>Active</option>
                    <option value={false}>Inactive</option>
                  </select>

                  <span className="text-danger">{errors.status?.message}</span>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* <CButton color="light" onClick={() => setVisible(false)}>
              Close
            </CButton> */}
            <button className="btn btn-primary-blue" type="submit" onClick={handleSubmit(onSubmit)}>
              {'Save'}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </React.Fragment>
  )
}
EditCustSubs.propTypes = {
  data: PropTypes.object,
  page: PropTypes.number,
  fetchList: PropTypes.func,
  selCust: PropTypes.array,
}

export default EditCustSubs
