

import React, { useState, useEffect } from 'react'
import { Edit, Trash2, Eye, EyeOff, Archive } from 'react-feather'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import SweetAlert from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify'


import {
  getVesselTypeApi,
  createVesselTypeApi,
  deleteVesselTypeApi,
  updateVesselTypeApi,
} from '../../../common/Api/index'
import { calMaxPage } from '../../../common/Functions/CommonFunctions'
import EditVesselType from './EditVesselType'
import { OverlayTrigger, Modal, Pagination, Spinner, Tooltip } from 'react-bootstrap'

const schema = yup
  .object()
  .shape({
    name: yup.string().max(30, '30 characters allowed !').required('Name is required !'),
  })
  .required()

function VesselTypeMaster() {
  const [visible, setVisible] = useState(false)
  const [vesseltype, setVesseltype] = useState({ data: [], loading: false })

  const [page, setPage] = useState(1)
  const [maxPageAndTotalCount, setMaxPageAndTotalCount] = useState({ maxPage: '', totalCount: '' })
  const [srNo, setSrNo] = useState(0)
  const { register, handleSubmit, formState, control, watch, setValue, getValues, reset } = useForm(
    {
      resolver: yupResolver(schema),
      mode: 'onChange',
    },
  )
  let { errors } = formState

  useEffect(() => {
    fetchVesselTypes(page)
  }, [])

  const fetchVesselTypes = (page_val) => {
    setVesseltype({ data: [], loading: true })
    let params = { limit: 10, offset: page_val - 1 }

    getVesselTypeApi(params)
      .then((res) => {
        console.log('res', res.data)
        if (res.data.data.response.results.length > 0) {
          setVesseltype({ data: res.data.data.response.results, loading: false })

          setMaxPageAndTotalCount({
            ...maxPageAndTotalCount,
            maxPage: calMaxPage(res.data.data.response.count.totalData),
            totalCount: res.data.data.response.count.totalData,
          })
        } else {
          setVesseltype({ data: [], loading: false })
        }
      })
      .catch((er) => console.log(er))
  }

  const onSubmit = (data) => {
    console.log('data', data)
    if (data !== '') {
      createVesselTypeApi(data).then(
        (res) => {
          console.log('res', res)
          if (res.data.data.statusCode == 200) {
            setVisible(!visible)
            toast.success('Customer created successfully', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            })

            fetchVesselTypes(1)
            reset()
          }
        },
        (err) => {
          console.log('err', err)
          toast.error('Something went wrong !', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
        },
      )
    } else {
      errors.showMessages()
    }
  }

  function delVesselType(val) {
    SweetAlert.fire({
      title: 'Are you sure to delete? \n' + val.name,
      text: 'Once deleted, you will not be able to recover this record !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // console.log(val)
        // Firing delete api
        deleteVesselTypeApi({ id: val.id }).then(
          (res) => {
            console.log('res', res)
            toast.success('Vessel type deleted successfully', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            })
            fetchVesselTypes(page)
          },
          (err) => {
            console.log('err', err)
            toast.error('Something went wrong !', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            })
          },
        )
      }
    })
  }

  function softDelete(data) {
    console.log('data', data)
    data.isactive = false
    SweetAlert.fire({
      title: `Are you sure to delete subscription for ${data.name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        updateVesselTypeApi(data, { id: data.id }).then(
          (res) => {
            console.log('res', res)
            toast.success('Customer subscription deleted successfully', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            })
            fetchVesselTypes(page)
          },
          (err) => {
            console.log('err', err)
            toast.error('Something went wrong !', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            })
          },
        )
      }
    })
  }

  return (
    <div className="mb-4">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="card">
        <div className="card-header bg-primary-blue" style={{ fontSize: '20px' }}>
          Vessel Type List
        </div>
        <div className="card-body">
          <div className="d-flex justify-content-end">
            <button
              className="btn bg-primary-blue"
              onClick={() => {
                setVisible(true)
              }}
            >
              Create VesselType
            </button>
          </div>

          <>
            <div className=" mt-4">
              {vesseltype.loading ? (
                <Spinner animation="border" variant="primary" />
              ) : vesseltype.data.length > 0 ? (
                <div className="table-responsive" style={{ maxHeight: '60vh', overflowX: 'auto' }}>
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr
                        className="poppins-bold"
                        style={{ position: 'sticky', top: '0', background: 'white' }}
                      >
                        <th scope="col">Sr. No</th>
                        <th scope="col">Name</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {vesseltype.data.map((item, index) => (
                        <tr key={item.id}>
                          <th scope="row">{index + 1 + srNo}</th>
                          <td>{item.name}</td>
                          <td>{item.isactive ? 'Active' : 'Non active'}</td>
                          <td>
                            <EditVesselType fetchVesselTypes={fetchVesselTypes} data={item} />

                  
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={<Tooltip id="button-tooltip-1">Permanant Delete</Tooltip>}
                            >
                              <Trash2
                              size={18}
                              className="cursor-pointer ms-1"
                              onClick={() => delVesselType(item)}
                            />
                            </OverlayTrigger>
                           




                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={<Tooltip id="button-tooltip-1">Temporary Delete</Tooltip>}
                            >
                              <Archive
                                className="cursor-pointer ms-1"
                                size={18}
                                onClick={() => softDelete(item)}
                              />
                            </OverlayTrigger>

                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className="m-0 text-center text-danger">{'No Data Found !'}</p>
              )}

              {vesseltype.data.length > 0 ? (
                <div className="mt-4">
                  <Pagination
                    className=" cursor_pointer justify-content-end pagination-primary"
                    aria-label="Page navigation example"
                  >
                    <Pagination.Item
                      onClick={() => {
                        fetchVesselTypes(page - 1)
                        setPage((prevP) => prevP - 1)
                        setSrNo((prevC) => prevC - 10)
                      }}
                      disabled={page === 1 ? true : false}
                    >
                      Previous
                    </Pagination.Item>
                    <Pagination.Item>{page}</Pagination.Item>
                    <Pagination.Item
                      onClick={() => {
                        fetchVesselTypes(page + 1)
                        setPage((prevP) => prevP + 1)
                        setSrNo((prevC) => prevC + 10)
                      }}
                      disabled={
                        page === maxPageAndTotalCount.maxPage || maxPageAndTotalCount.maxPage === 0
                          ? true
                          : false
                      }
                    >
                      Next
                    </Pagination.Item>
                  </Pagination>
                  <div>
                    <div className="d-flex justify-content-end">
                      <p
                        className="mb-0"
                        style={{ fontSize: '14px' }}
                      >{`${page} / ${maxPageAndTotalCount.maxPage} pages`}</p>
                    </div>
                    <div className="d-flex justify-content-end">
                      <p
                        className=""
                        style={{ fontSize: '14px' }}
                      >{`Total Records: ${maxPageAndTotalCount.totalCount}`}</p>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </>
        </div>
      </div>
      <Modal size="lg" show={visible} onHide={() => setVisible(false)} backdrop="static">
        <form className="">
          <Modal.Header className="bg-primary-blue" closeButton>
            <Modal.Title className="">Create Vessel Type</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Name'}</label>
                  <input
                    className={errors.name ? 'form-control is-invalid' : 'form-control'}
                    type="text"
                    placeholder="Name*"
                    name="name"
                    {...register('name')}
                  />

                  <span className="text-danger">{errors.name?.message}</span>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* <CButton color="secondary" onClick={() => setVisible(false)}>
              Close
            </CButton> */}
            <button className="btn btn-primary-blue" type="submit" onClick={handleSubmit(onSubmit)}>
              {'Save'}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  )
}

export default VesselTypeMaster

