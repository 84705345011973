import {
  login,
  addVessel,
  getVessel,
  updateVessel,
  vesselTypes,
  allCountries,
  delVessel,
  addRestHours,
  getRestHours,
  getCustomer,
  delCustomer,
  updateCustomer,
  generateSignUrl,
  getVesselAdmins,
  createVesselAdmins,
  deleteVesselAdmin,
  updateVesselAdmin,
  forgetPassword,
  resetPassword,
  addVesselType,
  updateVesselType,
  deleteVesselType,
  addCustomer,
  getApplications,
  getSubscription,
  addSubscription,
  updateSubscription,
  deleteSubscription,
  getCustSubscription,
  createCustSubscription,
  updateCustSubscription,
  deleteCustSubs,
  createCustAdmin,
} from './constants'
import axiosInstance from './interceptor'

export const loginapi = (payload) => {
  return axiosInstance.post(login, payload)
}

export const getVesselApi = (payload) => {
  return axiosInstance.get(getVessel, { params: payload })
}

export const createVesselApi = (payload) => {
  return axiosInstance.post(addVessel, payload)
}

export const delVesselApi = (payload) => {
  return axiosInstance.delete(delVessel, { params: payload })
}
export const updateVesselApi = (payload, id) => {
  return axiosInstance.put(updateVessel, payload, { params: id })
}


export const getCountriesApi = () => {
  return axiosInstance.get(allCountries)
}

export const postRestHoursApi = (payload) => {
  return axiosInstance.post(addRestHours, payload)
}

export const getRestHoursApi = (payload) => {
  return axiosInstance.get(getRestHours, { params: payload })
}



export const generateSignUrlApi = (payload) => {
  return axiosInstance.post(generateSignUrl, payload)
}

export const getVesselAdminsApi = (params) => {
  return axiosInstance.get(getVesselAdmins, { params: params })
}
export const createVesselAdminsApi = (payload) => {
  return axiosInstance.post(createVesselAdmins, payload)
}
export const delVesselAdminsApi = (params) => {
  return axiosInstance.delete(deleteVesselAdmin, { params: params })
}

export const updateVesselAdminApi = (payload, params) => {
  return axiosInstance.put(updateVesselAdmin, payload, { params: params })
}
export const forgetPasswordApi = (payload) => {
  return axiosInstance.post(forgetPassword, payload)
}
export const passwordResetApi = (payload) => {
  return axiosInstance.put(resetPassword, payload)
}

//  CRUD Vessel Type api's 
export const getVesselTypeApi = (params) => {
  return axiosInstance.get(vesselTypes, { params: params })
}
export const createVesselTypeApi = (payload) => {
  return axiosInstance.post(addVesselType, payload)
}

export const updateVesselTypeApi = (payload, params) => {
  return axiosInstance.put(updateVesselType, payload, { params: params })
}
export const deleteVesselTypeApi = (params) => {
  return axiosInstance.delete(deleteVesselType, { params })
}

// Customer  CRUD Api's
export const getCustomerApi = (payload) => {
  return axiosInstance.get(getCustomer, { params: payload })
}

export const createCustApi = (payload) => {
  return axiosInstance.post(addCustomer, payload)
}
export const updateCustApi = (payload, params) => {
  return axiosInstance.put(updateCustomer, payload, { params: params })
}

export const DelCustomerApi = (payload) => {
  return axiosInstance.delete(delCustomer, { params: payload })
}

// Function for logo 

export const createLogoApi = (payload) => {
  return axiosInstance.post(generateSignUrl, payload)
}

// get application's 
export const fetchApplications = (payload) => {
  return axiosInstance.get(getApplications, { params: payload })
}

// CRUD for subcription's 

export const getSubscriptionsApi = (payload) => {
  return axiosInstance.get(getSubscription, { params: payload })
}
export const createSubscriptionsApi = (payload) => {
  return axiosInstance.post(addSubscription, payload)
}
export const updateSubscriptionsApi = (payload, params) => {
  return axiosInstance.put(updateSubscription, payload, { params: params })
}
export const deleteSubscriptionsApi = (params) => {
  return axiosInstance.delete(deleteSubscription, { params: params })
}

// CRUD for Customer Subcription's 
export const getCustSubscriptionApi = (params) => {
  return axiosInstance.get(getCustSubscription, { params: params })
}
export const createCustSubscriptionApi = (payload) => {
  return axiosInstance.post(createCustSubscription, payload)
}
export const updateCustSubscriptionApi = (payload, params) => {
  return axiosInstance.put(updateCustSubscription, payload, { params: params })
}

export const deleteCustSubsApi = (params) => {
  return axiosInstance.delete(deleteCustSubs, { params: params })
}

// Customer admin api

export const createCustAdminApi = (payload) => {
  return axiosInstance.post(createCustAdmin, payload)
}