export const login = '/login-user'
export const addVessel = '/add-vessel'
export const getVessel = '/all-vessel'
export const delVessel = '/delete-vessel'
export const updateVessel = '/update-vessel'
export const allCountries = '/all-country'
export const addRestHours = 'add-rest-hours'
export const getRestHours = 'all-rest-hours'

export const generateSignUrl = 'generate-signature-file-url'
export const getVesselAdmins = 'get-vessel-admins'
export const createVesselAdmins = 'add-vessel-admin'
export const deleteVesselAdmin = 'delete-vessel-admin'
export const updateVesselAdmin = 'update-vessel-admin'
export const forgetPassword = '/forgot-password'
export const resetPassword = '/reset-password'

// Vessel type constants 
export const vesselTypes = 'all-vessel-types'
export const addVesselType='add-vessel-type'
export const updateVesselType='update-vessel-type'
export const deleteVesselType='delete-vessel-type'

// customer constants
export const getCustomer = 'get-customers'
export const addCustomer = 'add-customer'
export const delCustomer = 'delete-customer'
export const updateCustomer = 'update-customer'

//  application constants
export const getApplications = 'get-applications'

// Subcriptions constants
  export const getSubscription = 'get-subscriptions'
export const addSubscription = 'add-subscription'
export const updateSubscription = 'update-subscription'
export const deleteSubscription = 'delete-subscription'

// Customer Subcription's constant's 

export const getCustSubscription = 'get-customer-subscriptions'
export const createCustSubscription = 'add-customer-subscription'
export const updateCustSubscription = 'update-customer-subscription'
export const deleteCustSubs = 'delete-customer-subscription'

// Add Customer admin
export const createCustAdmin = 'add-customer-admin'
