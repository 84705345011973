import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
// import logo from '../../assets/images/project/DILogoBig.png'
import logo from '../../assets/images/project/vedampng.png'
import man from '../../assets/images/project/user.png'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast, ToastContainer } from 'react-toastify'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { loginapi } from '../../common/Api'

const schema = yup
  .object()
  .shape({
    username: yup.string().required('Username is required !'),
    password: yup.string().required('Password is required !'),
  })
  .required()

function AppLogin() {
  const [value, setValue] = useState(localStorage.getItem('profileURL' || man))
  const [loginClick, setLoginClick] = useState(false)
  let navigate = useNavigate()

  useEffect(() => {
    // console.log('userInfo', userInfo)
    if (value !== null) localStorage.setItem('profileURL', value)
    else localStorage.setItem('profileURL', man)
  }, [value])

  useEffect(() => {}, [])

  const { register, handleSubmit, control, formState, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  }) // initialise the hook

  let { errors } = formState

  const onSubmit = (data) => {
    setLoginClick(true)
    //console.log('data', data)
    const payload = {
      username: data.username,
      password: data.password,
    }
    loginapi(payload).then(
      (res) => {
        console.log('res', res)
        if (res.data.data.statusCode === 200) {
          localStorage.setItem('token', res.data.data.response.token)
          const data = res.data.data.response
          delete data.token
          localStorage.setItem('loginData', JSON.stringify(res.data.data.response))

          toast.success('Login successfully', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })

          if (res.data.data.response.role == 'super_admin') {
            navigate(`/customers`)
          } 
        }
      },
      (err) => {
        console.log('err', err)
        if (err.response.status == 400) {
          setLoginClick(false)
          toast.error(err.response.data.data.response, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
        }
      },
    )
  }

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="app_login" style={{ marginTop: '50px' }}>
        <div className="container">
          <div className="row ">
            <div className="col-md-12 ">
              <div className="auth-innerright">
                <div className="authentication-box ">
                  <div className="text-center">
                    <img src={logo} width={'180px'} height={'160px'} alt="" />
                   
                  </div>
                  <div className="card mt-4 shadow-lg mx-auto" style={{ maxWidth: '600px' }}>
                    <div className="card-body">
                      <div className="text-center">
                        <h4>{'Sign In'}</h4>
                        <h6>{'Enter Super Admin Username & Password'} </h6>
                        
                      </div>
                      <form>
                        <div className="form-group">
                          <label className="col-form-label fw-bold pt-0">{'Username'}</label>
                          <input
                            className="form-control"
                            type="text"
                            name="username"
                            // value={email}
                            // onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter username"
                            {...register('username')}
                          />
                          <span className="text-danger">{errors.username?.message}</span>
                        </div>
                        <div className="form-group">
                          <label className="col-form-label fw-bold">{'Password'}</label>
                          <input
                            className="form-control"
                            type="password"
                            name="password"
                            // value={password}
                            // onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter password"
                            {...register('password')}
                          />
                          <span className="text-danger">{errors.password?.message}</span>
                        </div>

                        <div className="form-group form-row mt-4 mb-0 d-grid">
                          {loginClick ? (
                            <button className="btn btn-primary-blue" disabled>
                              Sign In
                            </button>
                          ) : (
                            <button
                              className="btn btn-primary-blue"
                              type="submit"
                              onClick={handleSubmit(onSubmit)}
                            >
                              {'Sign In'}
                            </button>
                          )}
                          <div className="text-center mt-2" style={{ fontSize: '14px' }}>
                            <span>Version {process.env.REACT_APP_VERSION}</span>
                          </div>
                        </div>
                    
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AppLogin
